import { Menu } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import Button from "../components/basics/button";
import PortalHeader from "../components/basics/portal-header";
import CartTable from "../components/widgets/cart-table";
import PortalSidebar from "../components/widgets/portal-sidebar";
import { logout } from "../helpers/firebase";
import { formatter } from "../helpers/functions";
import { useAppContext } from "../helpers/store";
import SideView from "../components/widgets/side-view";
import LabeledInput from "../components/basics/labeled-input";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { io } from "socket.io-client";

function PortalLayout({ children, displayName, headerText, uid }) {
  const { height } = useWindowSize();
  const context = useAppContext();
  const [show, setShow] = useState(false);
  const [roomType, setRoomType] = useState("");

  const categories = context?.menu?.map((item) => item?.data?.category);
  const distinctCategories = [...new Set(categories)];
  distinctCategories.unshift("Choose a category");

  const barCategories = context?.barMenu?.map((item) => item?.data?.category);
  const distinctBarCategories = [...new Set(barCategories)];
  distinctBarCategories.unshift("Choose a category");

  const socket = io("wss://api.ibomwaterfallsuites.com/paystack-webhook");

  const [updateAdminLoading, setUpdateAdminLoading] = useState(false);
  const [deleteAdminLoading, setDeleteAdminLoading] = useState(false);
  const [createAdminLoading, setCreateAdminLoading] = useState(false);
  const [updateItemLoading, setUpdateItemLoading] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [addItemLoading, setAddItemLoading] = useState(false);
  const [deleteItemLoading, setDeleteItemLoading] = useState(false);
  const [bookRoomLoading, setBookRoomLoading] = useState(false);
  const [updateRoomLoading, setUpdateRoomLoading] = useState(false);

  const [newMenuItem, setNewMenuItem] = useState({
    name: "",
    price: 0,
    category: "Choose a category",
  });

  const [newAdmin, setNewAdmin] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    role: "",
  });

  let today = moment();
  let tomorrow = moment();
  tomorrow.add(1, "days");

  const [newBooking, setNewBooking] = useState({
    firstname: "",
    lastname: "",
    middlename: "",
    email: "",
    discountedPrice: "",
    dateIn: `${today.year()}-${
      today.month() + 1 < 10 ? "0" + (today.month() + 1) : today.month() + 1
    }-${today.date() < 10 ? "0" + today.date() : today.date()}`,
    dateOut: `${tomorrow.year()}-${
      tomorrow.month() < 10
        ? "0" + (tomorrow.month() + 1)
        : tomorrow.month() + 1
    }-${tomorrow.date() < 10 ? "0" + tomorrow.date() : tomorrow.date()}`,
  });

  const superRegex = /super|manager/;
  const receptionRegex = /super|manager|reception/;
  const ordersRegex = /super|manager|restaurant|bar/;

  const getSideView = (mode) => {
    switch (mode) {
      case "cart":
        return (
          <div className="w-full">
            <p className="text-2xl font-medium tracking-wide mb-14">
              Your Cart
            </p>
            <CartTable />
            <div className="flex items-center justify-between w-full p-4 bg-[#28A745] bg-opacity-10 rounded-lg mt-20 mb-10">
              <p className="text-sm font-light">Total Payable Amount</p>
              <p className="text-2xl font-medium">
                {formatter.format(
                  context?.cart?.reduce(
                    (sum, item) => sum + item.price * item.quantity,
                    0
                  )
                )}
              </p>
            </div>
            <Button
              className=" h-16 bg-dark text-white"
              label="Place Order with Paystack"
              disabled={context?.cart?.length === 0}
              onClick={async () => {
                setPlaceOrderLoading(true);
                await fetch(
                  "https://api.ibomwaterfallsuites.com/create-order-pos-payment",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      cart: [...context?.cart],
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then(async (data) => {
                    if (data.status) {
                      await fetch(
                        "https://api.ibomwaterfallsuites.com/push-pos-payment",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            transactionId: data.data.id,
                            reference: data.data.offline_reference,
                            paystackId: "2232YK41",
                          }),
                        }
                      );
                      socket.on("payment_success", async () => {
                        // Do whatever you want when the payment is successful
                        await fetch(
                          "https://api.ibomwaterfallsuites.com/update-orders",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              bookingId: "admin",
                              uid,
                              transactionReference: data.data.offline_reference,
                              items: [...context?.cart],
                              status: "Preparing",
                              createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                            }),
                          }
                        ).then(() => {
                          context?.setcart([]);
                          context?.setrefresh(context?.refresh + 1);
                          setPlaceOrderLoading(false);
                          context?.setOpenSideView(false);
                        });
                        console.log("Payment successful");
                        setPlaceOrderLoading(false);
                      });
                    }
                  });
              }}
              loading={placeOrderLoading}
            />
            <Button
              className="mt-6 h-16 bg-dark text-white"
              label="Manual Order"
              disabled={context?.cart?.length === 0}
              onClick={async () => {
                setPlaceOrderLoading(true);
                await fetch(
                  "https://api.ibomwaterfallsuites.com/update-orders",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      bookingId: "admin",
                      uid,
                      transactionReference: uuidv4(),
                      items: [...context?.cart],
                      status: "Preparing",
                      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                    }),
                  }
                ).then(() => {
                  context?.setcart([]);
                  context?.setrefresh(context?.refresh + 1);
                  setPlaceOrderLoading(false);
                  context?.setOpenSideView(false);
                });
              }}
              loading={placeOrderLoading}
            />
          </div>
        );
      case "edit-item":
        return (
          <div className="w-full ">
            <p className="text-2xl font-medium tracking-wide my-9">Edit Item</p>
            <div className="mb-16">
              <LabeledInput
                label="Name"
                className="border border-dark rounded-md pl-3"
                value={context?.editable?.name}
                onChange={(e) =>
                  context?.setEditable({
                    ...context?.editable,
                    name: e.target.value,
                  })
                }
              />
              <LabeledInput
                label="Price"
                className="border border-dark rounded-md pl-3"
                value={context?.editable?.price}
                onChange={(e) =>
                  context?.setEditable({
                    ...context?.editable,
                    price: e.target.value,
                  })
                }
              />
              <div className="my-10">
                <select
                  className="outline-none"
                  onChange={(e) => {
                    context.setEditable({
                      ...context?.editable,
                      category: e.target.value,
                    });
                  }}
                  value={context?.editable?.category}
                >
                  {context?.menuMode === "bar"
                    ? distinctBarCategories?.map((category) => (
                        <option key={category} value={category}>
                          {category.toUpperCase()}
                        </option>
                      ))
                    : distinctCategories?.map((category) => (
                        <option key={category} value={category}>
                          {category.toUpperCase()}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className="w-full flex justify-between items-center">
              <Button
                className="w-60 h-16 bg-transparent text-[#FA2828]"
                label="Remove"
                onClick={async () => {
                  setDeleteItemLoading(true);
                  await fetch(
                    `${
                      context?.menuMode === "restaurant"
                        ? `https://api.ibomwaterfallsuites.com/delete-menu-item/${context?.editable?.id}`
                        : `https://api.ibomwaterfallsuites.com/delete-bar-menu-item/${context?.editable?.id}`
                    }`,
                    {
                      method: "DELETE",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                    .then(() => {
                      context?.setrefresh(context?.refresh + 1);
                      setDeleteItemLoading(false);
                      context?.setEditable({
                        name: "",
                        price: 0,
                        category: "",
                        id: "",
                      });
                      context?.setOpenSideView(false);
                    })
                    .catch((e) => {
                      alert(e.message);
                      setDeleteItemLoading(false);
                      context?.setEditable({
                        name: "",
                        price: 0,
                        category: "",
                        id: "",
                      });
                      context?.setOpenSideView(false);
                    });
                }}
                loading={deleteItemLoading}
              />
              <Button
                className="w-60 h-16 bg-dark text-white"
                label="Update"
                onClick={async () => {
                  setUpdateItemLoading(true);
                  await fetch(
                    `${
                      context?.menuMode === "restaurant"
                        ? `https://api.ibomwaterfallsuites.com/update-menu-item`
                        : `https://api.ibomwaterfallsuites.com/update-bar-menu-item`
                    }`,
                    {
                      method: "POST",
                      body: JSON.stringify(context?.editable),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                    .then(() => {
                      setUpdateItemLoading(false);
                      context?.setEditable({
                        name: "",
                        price: 0,
                        category: "",
                        id: "",
                      });
                      context?.setOpenSideView(false);
                      context?.setrefresh(context?.refresh + 1);
                    })
                    .catch((e) => {
                      alert(e.message);
                      setUpdateItemLoading(false);
                      context?.setEditable({
                        name: "",
                        price: 0,
                        category: "",
                        id: "",
                      });
                      context?.setOpenSideView(false);
                      context?.setrefresh(context?.refresh + 1);
                    });
                }}
                loading={updateItemLoading}
              />
            </div>
          </div>
        );
      case "add-item":
        return (
          <div className="w-full ">
            <p className="text-2xl font-medium tracking-wide my-9">Add Item</p>
            <div className="mb-16">
              <LabeledInput
                label="Name"
                className="border border-dark rounded-md pl-3"
                value={newMenuItem.name}
                onChange={(e) =>
                  setNewMenuItem({ ...newMenuItem, name: e.target.value })
                }
              />
              <LabeledInput
                label="Price"
                className="border border-dark rounded-md pl-3"
                value={newMenuItem.price}
                onChange={(e) =>
                  setNewMenuItem({
                    ...newMenuItem,
                    price: /\d/.test(e.target.value)
                      ? parseInt(e.target.value)
                      : "",
                  })
                }
              />
              <div className="my-10">
                <select
                  className="outline-none"
                  onChange={(e) => {
                    setNewMenuItem({
                      ...newMenuItem,
                      category: e.target.value,
                    });
                  }}
                  value={newMenuItem.category}
                >
                  {context?.menuMode === "bar"
                    ? distinctBarCategories?.map((category) => (
                        <option key={category} value={category}>
                          {category.toUpperCase()}
                        </option>
                      ))
                    : distinctCategories?.map((category) => (
                        <option key={category} value={category}>
                          {category.toUpperCase()}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <Button
              className="lg:w-60 lg:h-16 bg-dark text-white"
              label="Add"
              onClick={async () => {
                setAddItemLoading(true);
                await fetch(
                  `${
                    context?.menuMode === "restaurant"
                      ? "https://api.ibomwaterfallsuites.com/add-menu-item"
                      : "https://api.ibomwaterfallsuites.com/add-bar-menu-item"
                  }`,
                  {
                    method: "POST",
                    body: JSON.stringify(newMenuItem),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then(() => {
                    setAddItemLoading(false);
                    setNewMenuItem({
                      name: "",
                      price: 0,
                      category: "",
                      id: "",
                    });
                    context?.setrefresh(context?.refresh + 1);
                    context?.setOpenSideView(false);
                  })
                  .catch((e) => {
                    alert(e.message);
                    setAddItemLoading(false);
                    setNewMenuItem({
                      name: "",
                      price: 0,
                      category: "",
                    });
                    context?.setrefresh(context?.refresh + 1);
                    context?.setOpenSideView(false);
                  });
              }}
              loading={addItemLoading}
            />
          </div>
        );
      case "add-admin":
        return (
          <div className="w-full ">
            <p className="text-2xl font-medium tracking-wide my-9">Add Admin</p>
            <div className="mb-16">
              <LabeledInput
                label="First Name"
                className="border border-dark rounded-md pl-3"
                value={newAdmin.firstname}
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, firstname: e.target.value })
                }
              />
              <LabeledInput
                label="Last Name"
                className="border border-dark rounded-md pl-3"
                value={newAdmin.lastname}
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, lastname: e.target.value })
                }
              />
              <LabeledInput
                label="Email"
                className="border border-dark rounded-md pl-3"
                value={newAdmin.email}
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, email: e.target.value })
                }
              />
              <LabeledInput
                label="Role"
                className="border border-dark rounded-md pl-3"
                value={newAdmin.role}
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, role: e.target.value })
                }
              />
              <LabeledInput
                label="Password"
                className="border border-dark rounded-md pl-3"
                value={newAdmin.password}
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, password: e.target.value })
                }
              />
            </div>
            <Button
              className="w-60 h-16 bg-dark text-white"
              label="Create Admin"
              onClick={async () => {
                setCreateAdminLoading(true);
                await fetch(
                  "https://api.ibomwaterfallsuites.com/create-admin",
                  {
                    method: "POST",
                    body: JSON.stringify(newAdmin),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then(() => {
                    context?.setrefresh(context?.refresh + 1);
                    setCreateAdminLoading(false);
                    setNewAdmin({
                      firstname: "",
                      lastname: "",
                      email: "",
                      password: "",
                      role: "",
                    });
                    context?.setOpenSideView(false);
                  })
                  .catch((e) => {
                    alert(e.message);
                    setCreateAdminLoading(false);
                    setNewAdmin({
                      firstname: "",
                      lastname: "",
                      email: "",
                      password: "",
                      role: "",
                    });
                    context?.setOpenSideView(false);
                  });
              }}
              loading={createAdminLoading}
            />
          </div>
        );
      case "edit-admin":
        return (
          <div className="w-full ">
            <p className="text-2xl font-medium tracking-wide my-9">
              Edit Admin
            </p>
            <div className="mb-16">
              <LabeledInput
                label="First Name"
                className="border border-dark rounded-md pl-3"
                value={context?.adminable.firstname}
                onChange={(e) =>
                  context?.setAdminable({
                    ...context?.adminable,
                    firstname: e.target.value,
                  })
                }
              />
              <LabeledInput
                label="Last Name"
                className="border border-dark rounded-md pl-3"
                value={context?.adminable.lastname}
                onChange={(e) =>
                  context?.setAdminable({
                    ...context?.adminable,
                    lastname: e.target.value,
                  })
                }
              />
            </div>
            <div className="w-full flex justify-between items-center">
              <Button
                className="w-60 h-16 bg-transparent text-[#FA2828]"
                label="Remove"
                loading={deleteAdminLoading}
                onClick={async () => {
                  setDeleteAdminLoading(true);
                  await fetch(
                    `https://api.ibomwaterfallsuites.com/delete-admin/${context?.adminable?.uid}`,
                    {
                      method: "delete",
                    }
                  ).then(() => {
                    setDeleteAdminLoading(false);
                    context?.setrefresh(context?.refresh + 1);
                    context?.setOpenSideView(false);
                  });
                }}
              />
              <Button
                className="w-60 h-16 bg-dark text-white"
                label="Update"
                loading={updateAdminLoading}
                onClick={async () => {
                  setUpdateAdminLoading(true);
                  await fetch(
                    "https://api.ibomwaterfallsuites.com/update-admin",
                    {
                      method: "POST",
                      body: JSON.stringify(context?.adminable),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  )
                    .then(() => {
                      setUpdateAdminLoading(false);
                      context?.setAdminable({
                        firstname: "",
                        lastname: "",
                      });
                      context?.setOpenSideView(false);
                      context?.setrefresh(context?.refresh + 1);
                    })
                    .catch((e) => {
                      alert(e.message);
                      setUpdateAdminLoading(false);
                      context?.setAdminable({
                        firstname: "",
                        lastname: "",
                      });
                      context?.setOpenSideView(false);
                      context?.setrefresh(context?.refresh + 1);
                    });
                }}
              />
            </div>
          </div>
        );
      case "manage-room":
        return (
          <div className="w-full lg:px-8">
            <p className="text-2xl font-medium tracking-wide my-9">
              Manage {context?.bookable?.roomId}
            </p>
            <div className="mb-16">
              <LabeledInput
                label="First Name"
                className="bg-gray-200 rounded-md pl-3"
                value={newBooking.firstname}
                onChange={(e) =>
                  setNewBooking({ ...newBooking, firstname: e.target.value })
                }
              />
              <LabeledInput
                label="Last Name"
                className="bg-gray-200 rounded-md pl-3"
                value={newBooking.lastname}
                onChange={(e) =>
                  setNewBooking({ ...newBooking, lastname: e.target.value })
                }
              />
              <LabeledInput
                label="Email"
                className="bg-gray-200 rounded-md pl-3"
                value={newBooking.email}
                onChange={(e) =>
                  setNewBooking({ ...newBooking, email: e.target.value })
                }
              />
              <LabeledInput
                label="Discouted Price"
                className="bg-gray-200 rounded-md pl-3"
                value={newBooking.discountedPrice}
                onChange={(e) =>
                  setNewBooking({
                    ...newBooking,
                    discountedPrice: /\d/.test(e.target.value)
                      ? parseInt(e.target.value)
                      : "",
                  })
                }
              />
              <div className="flex w-full justify-between rounded border border-dark p-4">
                <div className="lg:mr-3">
                  <p className="text-xs opacity-50 mb-1">Check-in</p>
                  <input
                    type="date"
                    className="text-sm font-medium outline-none bg-transparent"
                    onChange={(e) =>
                      setNewBooking({ ...newBooking, dateIn: e.target.value })
                    }
                    value={newBooking.dateIn}
                  />
                </div>
                <div className="lg:mr-3">
                  <p className="text-xs opacity-50 mb-1">Check-out</p>
                  <input
                    type="date"
                    className="text-sm font-medium outline-none bg-transparent"
                    onChange={(e) =>
                      setNewBooking({ ...newBooking, dateOut: e.target.value })
                    }
                    value={newBooking.dateOut}
                  />
                </div>
              </div>
              <p>
                Booking for{" "}
                {moment(newBooking.dateOut).diff(newBooking.dateIn, "days") > 1
                  ? `${moment(newBooking.dateOut).diff(
                      newBooking.dateIn,
                      "days"
                    )} days`
                  : `${moment(newBooking.dateOut).diff(
                      newBooking.dateIn,
                      "days"
                    )} day`}
              </p>
            </div>
            <Button
              className="h-16 bg-dark text-white"
              label={"Manual Payment"}
              onClick={async () => {
                setBookRoomLoading(true);

                await fetch(
                  "https://api.ibomwaterfallsuites.com/check-availability",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      dateIn: newBooking.dateIn,
                      dateOut: newBooking.dateOut,
                      numOccupants: context?.bookable?.roomtype[0],
                    }),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(async (data) => {
                    if (data.includes(context?.bookable?.roomId)) {
                      await fetch(
                        "https://api.ibomwaterfallsuites.com/update-calendar",
                        {
                          method: "POST",
                          body: JSON.stringify({
                            ...newBooking,
                            numDays: moment(newBooking.dateOut).diff(
                              newBooking.dateIn,
                              "days"
                            ),
                            roomIds: [context?.bookable?.roomId],
                            roomtype: context?.bookable?.roomtype,
                            bookingType:
                              parseInt(context?.bookable?.roomtype[0]) < 5
                                ? "bedroom"
                                : "hall",
                            transactionReference: uuidv4(),
                            tab: [],
                          }),
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then(() => {
                          setBookRoomLoading(false);
                          console.log(
                            `${tomorrow.year()}-${
                              tomorrow.month() < 10
                                ? "0" + tomorrow.month() + 1
                                : tomorrow.month() + 1
                            }-${
                              tomorrow.date() < 10
                                ? "0" + tomorrow.date()
                                : tomorrow.date()
                            }`
                          );
                          setNewBooking({
                            firstname: "",
                            lastname: "",
                            middlename: "",
                            email: "",
                            dateIn: `${today.year()}-${
                              today.month() + 1 < 10
                                ? "0" + (today.month() + 1)
                                : today.month() + 1
                            }-${
                              today.date() < 10
                                ? "0" + today.date()
                                : today.date()
                            }`,
                            dateOut: `${tomorrow.year()}-${
                              tomorrow.month() < 10
                                ? "0" + tomorrow.month() + 1
                                : tomorrow.month() + 1
                            }-${
                              tomorrow.date() < 10
                                ? "0" + tomorrow.date()
                                : tomorrow.date()
                            }`,
                            discountedPrice: "",
                          });
                          context?.setrefresh(context?.refresh + 1);
                          context?.setOpenSideView(false);
                        })
                        .catch((e) => {
                          alert(e.message);
                          setBookRoomLoading(false);
                          setNewBooking({
                            firstname: "",
                            lastname: "",
                            middlename: "",
                            email: "",
                            dateIn: `${today.year()}-${
                              today.month() + 1 < 10
                                ? "0" + (today.month() + 1)
                                : today.month() + 1
                            }-${
                              today.date() < 10
                                ? "0" + today.date()
                                : today.date()
                            }`,
                            dateOut: `${tomorrow.year()}-${
                              tomorrow.month() < 10
                                ? "0" + tomorrow.month() + 1
                                : tomorrow.month() + 1
                            }-${
                              tomorrow.date() < 10
                                ? "0" + tomorrow.date()
                                : tomorrow.date()
                            }`,
                            discountedPrice: "",
                          });
                          context?.setrefresh(context?.refresh + 1);
                          context?.setOpenSideView(false);
                        });
                    } else {
                      alert(
                        "Can't book this room on one or more of those days."
                      );
                      setBookRoomLoading(false);
                    }
                  });
              }}
              loading={bookRoomLoading}
            />
            <Button
              className="my-6 h-16 bg-dark text-white"
              label={"Add to Tab"}
              onClick={async () => {
                setBookRoomLoading(true);
                await fetch(
                  "https://api.ibomwaterfallsuites.com/check-availability",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      dateIn: newBooking.dateIn,
                      dateOut: newBooking.dateOut,
                      numOccupants: context?.bookable?.roomtype[0],
                    }),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(async (data) => {
                    if (data.includes(context?.bookable?.roomId)) {
                      console.log(newBooking.discountedPrice);
                      await fetch(
                        "https://api.ibomwaterfallsuites.com/update-calendar",
                        {
                          method: "POST",
                          body: JSON.stringify({
                            ...newBooking,
                            numDays: moment(newBooking.dateOut).diff(
                              newBooking.dateIn,
                              "days"
                            ),
                            roomIds: [context?.bookable?.roomId],
                            roomtype: context?.bookable?.roomtype,
                            bookingType:
                              parseInt(context?.bookable?.roomtype[0]) < 5
                                ? "bedroom"
                                : "hall",
                            transactionReference: uuidv4(),
                            tab: [
                              {
                                item: context?.bookable?.roomnumber,
                                price:
                                  newBooking.discountedPrice === ""
                                    ? context?.bookable?.price
                                    : newBooking.discountedPrice,
                              },
                            ],
                          }),
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then(() => {
                          setBookRoomLoading(false);
                          setNewBooking({
                            firstname: "",
                            lastname: "",
                            middlename: "",
                            email: "",
                            dateIn: `${today.year()}-${
                              today.month() + 1 < 10
                                ? "0" + (today.month() + 1)
                                : today.month() + 1
                            }-${
                              today.date() < 10
                                ? "0" + today.date()
                                : today.date()
                            }`,
                            dateOut: `${tomorrow.year()}-${
                              tomorrow.month() < 10
                                ? "0" + (tomorrow.month() + 1)
                                : tomorrow.month() + 1
                            }-${
                              tomorrow.date() < 10
                                ? "0" + tomorrow.date()
                                : tomorrow.date()
                            }`,
                            discountedPrice: "",
                          });
                          context?.setrefresh(context?.refresh + 1);
                          context?.setOpenSideView(false);
                        })
                        .catch((e) => {
                          alert(e.message);
                          setBookRoomLoading(false);
                          setNewBooking({
                            firstname: "",
                            lastname: "",
                            middlename: "",
                            email: "",
                            dateIn: `${today.year()}-${
                              today.month() + 1 < 10
                                ? "0" + (today.month() + 1)
                                : today.month() + 1
                            }-${
                              today.date() < 10
                                ? "0" + today.date()
                                : today.date()
                            }`,
                            dateOut: `${tomorrow.year()}-${
                              tomorrow.month() < 10
                                ? "0" + (tomorrow.month() + 1)
                                : tomorrow.month() + 1
                            }-${
                              tomorrow.date() < 10
                                ? "0" + tomorrow.date()
                                : tomorrow.date()
                            }`,
                            discountedPrice: "",
                          });
                          context?.setrefresh(context?.refresh + 1);
                          context?.setOpenSideView(false);
                        });
                    } else {
                      alert(
                        "Can't book this room on one or more of those days."
                      );
                      setBookRoomLoading(false);
                    }
                  });
              }}
              loading={bookRoomLoading}
            />
            <Button
              className=" h-16 bg-dark text-white"
              label="Place Order with Paystack"
              onClick={async () => {
                setBookRoomLoading(true);
                await fetch(
                  "https://api.ibomwaterfallsuites.com/check-availability",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      dateIn: newBooking.dateIn,
                      dateOut: newBooking.dateOut,
                      numOccupants: context?.bookable?.roomtype[0],
                    }),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(async (data) => {
                    if (data.includes(context?.bookable?.roomId)) {
                      await fetch(
                        "https://api.ibomwaterfallsuites.com/create-order-pos-payment",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({
                            cart: [
                              {
                                name: context?.bookable?.roomnumber,
                                price:
                                  newBooking.discountedPrice === ""
                                    ? context?.bookable?.price
                                    : newBooking.discountedPrice,
                                quantity: 1,
                              },
                            ],
                          }),
                        }
                      )
                        .then((res) => res.json())
                        .then(async (paymentdata) => {
                          if (paymentdata.status) {
                            await fetch(
                              "https://api.ibomwaterfallsuites.com/push-pos-payment",
                              {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                  transactionId: paymentdata.data.id,
                                  reference: paymentdata.data.offline_reference,
                                  paystackId: "2232YK40",
                                }),
                              }
                            ).then(() => {
                              socket.on("payment_success", async () => {
                                // Do whatever you want when the payment is successful
                                await fetch(
                                  "https://api.ibomwaterfallsuites.com/update-calendar",
                                  {
                                    method: "POST",
                                    body: JSON.stringify({
                                      ...newBooking,
                                      numDays: moment(newBooking.dateOut).diff(
                                        newBooking.dateIn,
                                        "days"
                                      ),
                                      roomIds: [context?.bookable?.roomId],
                                      roomtype: context?.bookable?.roomtype,
                                      bookingType:
                                        parseInt(
                                          context?.bookable?.roomtype[0]
                                        ) < 5
                                          ? "bedroom"
                                          : "hall",
                                      transactionReference:
                                        paymentdata.data.offline_reference,
                                      tab: [],
                                    }),
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                  .then(() => {
                                    setBookRoomLoading(false);
                                    setNewBooking({
                                      firstname: "",
                                      lastname: "",
                                      middlename: "",
                                      email: "",
                                      dateIn: `${today.year()}-${
                                        today.month() + 1 < 10
                                          ? "0" + (today.month() + 1)
                                          : today.month() + 1
                                      }-${
                                        today.date() < 10
                                          ? "0" + today.date()
                                          : today.date()
                                      }`,
                                      dateOut: `${tomorrow.year()}-${
                                        tomorrow.month() < 10
                                          ? "0" + (tomorrow.month() + 1)
                                          : tomorrow.month() + 1
                                      }-${
                                        tomorrow.date() < 10
                                          ? "0" + tomorrow.date()
                                          : tomorrow.date()
                                      }`,
                                      discountedPrice: "",
                                    });
                                    context?.setrefresh(context?.refresh + 1);
                                    context?.setOpenSideView(false);
                                  })
                                  .catch((e) => {
                                    alert(e.message);
                                    setBookRoomLoading(false);
                                    setNewBooking({
                                      firstname: "",
                                      lastname: "",
                                      middlename: "",
                                      email: "",
                                      dateIn: `${today.year()}-${
                                        today.month() + 1 < 10
                                          ? "0" + (today.month() + 1)
                                          : today.month() + 1
                                      }-${
                                        today.date() < 10
                                          ? "0" + today.date()
                                          : today.date()
                                      }`,
                                      dateOut: `${tomorrow.year()}-${
                                        tomorrow.month() < 10
                                          ? "0" + tomorrow.month() + 1
                                          : tomorrow.month() + 1
                                      }-${
                                        tomorrow.date() < 10
                                          ? "0" + tomorrow.date()
                                          : tomorrow.date()
                                      }`,
                                      discountedPrice: "",
                                    });
                                    context?.setrefresh(context?.refresh + 1);
                                    context?.setOpenSideView(false);
                                  });
                              });
                            });
                          }
                        });
                    } else {
                      alert(
                        "Can't book this room on one or more of those days."
                      );
                      setBookRoomLoading(false);
                    }
                  });
              }}
              loading={bookRoomLoading}
            />
          </div>
        );
      case "edit-room":
        return (
          <div className="w-full ">
            <p className="text-2xl font-medium tracking-wide my-9">
              Edit Room Prices
            </p>
            <div className="w-full grid grid-cols-3 gap-3 mb-8">
              {context?.roomPrices?.map((r) => (
                <div
                  className="bg-dark rounded-md p-3 flex items-center justify-center cursor-pointer"
                  onClick={() => setRoomType(r.id)}
                >
                  <p className="font-medium text-[#f5f5f5] text-center capitalize">
                    {r.id}
                  </p>
                </div>
              ))}
            </div>
            {roomType === "" ? (
              <p>Select a room to edit</p>
            ) : (
              <>
                <LabeledInput
                  label={roomType.toUpperCase()}
                  className="border border-dark rounded-md pl-3"
                  value={
                    context?.roomPrices?.find((r) => r.id === roomType).data
                      .price
                  }
                  onChange={(e) => {
                    let currentPrices = [...context?.roomPrices];
                    const currentRoomIndex = context?.roomPrices?.findIndex(
                      (r) => r.id === roomType
                    );
                    currentPrices[currentRoomIndex].data.price = /\d/.test(
                      e.target.value
                    )
                      ? parseInt(e.target.value)
                      : "";
                    context?.setRoomPrices(currentPrices);
                  }}
                  key={roomType}
                />
                <Button
                  label="Save"
                  loading={updateRoomLoading}
                  onClick={async () => {
                    setUpdateRoomLoading(true);
                    await fetch(
                      "https://api.ibomwaterfallsuites.com/update-room-price",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          roomType,
                          price: context?.roomPrices?.find(
                            (r) => r.id === roomType
                          ).data.price,
                        }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    );
                    setUpdateRoomLoading(false);
                  }}
                />
              </>
            )}
          </div>
        );
      default:
        return <div />;
    }
  };
  return (
    <div className="relative w-full">
      <div
        className={`${
          show ? "h-96" : "h-20"
        } transition-[height] duration-500 ease-in-out w-full lg:hidden flex flex-col px-6 py-3`}
      >
        <div className="w-full flex justify-between items-center mb-3">
          <img
            src="/images/ibw-logo.png"
            className="lg:w-[6%] md:w-[10%] w-[16%] cursor-pointer"
            alt="ibw logo"
          />
          <div onClick={() => setShow(!show)}>
            <Menu className="text-black cursor-pointer" fontSize="large" />
          </div>
        </div>
        {show && (
          <div className="py-2 flex flex-col gap-1.5">
            {ordersRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("orders");
                  setShow(!show);
                }}
              >
                Orders
              </p>
            )}
            {ordersRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("menu");
                  setShow(!show);
                }}
              >
                Menu
              </p>
            )}
            {ordersRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("bar-menu");
                  setShow(!show);
                }}
              >
                Bar Menu
              </p>
            )}
            {receptionRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("manage-rooms");
                  setShow(!show);
                }}
              >
                Manage Rooms
              </p>
            )}
            {receptionRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("reports");
                  setShow(!show);
                }}
              >
                Reports
              </p>
            )}
            {superRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("administrators");
                  setShow(!show);
                }}
              >
                Administrators
              </p>
            )}
            {receptionRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("bookings");
                  setShow(!show);
                }}
              >
                Bookings
              </p>
            )}
            {superRegex.test(context?.role) && (
              <p
                onClick={() => {
                  context?.setmode("content");
                  setShow(!show);
                }}
              >
                Manage Content
              </p>
            )}
            <p onClick={() => logout()}>Logout</p>
          </div>
        )}
      </div>
      <div className="flex bg-[#F8F8FA] text-dark w-full">
        <PortalSidebar
          displayName={displayName}
          setmode={context?.setmode}
          mode={context?.mode}
          role={context?.role}
        />
        <div className="w-full lg:w-full" style={{ height: height * 0.9 }}>
          <PortalHeader headerText={headerText} />
          <div className="w-full p-8 overflow-scroll h-full">{children}</div>
        </div>
      </div>
      <SideView>{getSideView(context?.sideMode)}</SideView>
    </div>
  );
}

export default PortalLayout;
