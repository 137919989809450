import moment from "moment";
import React from "react";
import { logout } from "../../helpers/firebase";
import SidebarOption from "../basics/sidebar-option";
import Button from "../basics/button";

function PortalSidebar({ displayName, mode, setmode, role }) {
  var currentTime = moment();

  var currentHour = currentTime.hours();

  var greeting;
  if (currentHour < 12) {
    greeting = "Good Morning";
  } else if (currentHour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  const superRegex = /super|manager/;
  const receptionRegex = /super|manager|reception/;
  const ordersRegex = /super|manager|restaurant|bar/;
  const options = [
    {
      image: "/images/order-icon.png",
      label: "Orders",
      mode: "orders",
    },
    {
      image: "/images/menu-icon.png",
      label: "Menu",
      mode: "menu",
    },
    {
      image: "/images/drinks.png",
      label: "Bar Menu",
      mode: "bar-menu",
    },
    {
      image: "/images/calendar-icon.png",
      label: "Manage Rooms",
      mode: "manage-rooms",
    },
    {
      image: "/images/caution-icon.png",
      label: "Reports and Complaints",
      mode: "reports",
    },
    // {
    //     image: '/images/calendar-icon.png',
    //     label: 'History',
    //     mode: 'history'
    // },
    {
      image: "/images/order-icon.png",
      label: "Bookings",
      mode: "bookings",
    },
    {
      image: "/images/cms.png",
      label: "Manage Content",
      mode: "content",
    },
    {
      image: "/images/user.png",
      label: "Administrators",
      mode: "administrators",
    },
    {
      image: "/images/logout-icon.png",
      label: "Logout",
      mode: "logout",
      logout: true,
    },
  ];
  return (
    <div className="hidden lg:flex flex-col h-full w-1/4 pt-6 font-inter bg-white">
      <div className="flex flex-col items-center w-full mt-2">
        <img src="/images/ibw-logo.png" className="w-1/4" alt="ibw logo" />
      </div>
      <p className="mt-6 pl-6 text-[#6C737C]">
        {greeting}, {displayName}!
      </p>
      <div className="flex flex-col gap-3 mt-8 px-6 pb-24 border-b border-b-[#E1E4E8]">
        {ordersRegex.test(role) && (
          <SidebarOption
            image={options[0].image}
            label={options[0].label}
            key={options[0].label}
            onClick={() => setmode(options[0].mode)}
            active={options[0].mode === mode}
          />
        )}
        {ordersRegex.test(role) && (
          <SidebarOption
            image={options[1].image}
            label={options[1].label}
            key={options[1].label}
            onClick={() => setmode(options[1].mode)}
            active={options[1].mode === mode}
          />
        )}
        {ordersRegex.test(role) && (
          <SidebarOption
            image={options[2].image}
            label={options[2].label}
            key={options[2].label}
            onClick={() => setmode(options[2].mode)}
            active={options[2].mode === mode}
          />
        )}
        {receptionRegex.test(role) && (
          <SidebarOption
            image={options[3].image}
            label={options[3].label}
            key={options[3].label}
            onClick={() => setmode(options[3].mode)}
            active={options[3].mode === mode}
          />
        )}
        {receptionRegex.test(role) && (
          <SidebarOption
            image={options[4].image}
            label={options[4].label}
            key={options[4].label}
            onClick={() => setmode(options[4].mode)}
            active={options[4].mode === mode}
          />
        )}
        {superRegex.test(role) && (
          <SidebarOption
            image={options[5].image}
            label={options[5].label}
            key={options[5].label}
            onClick={() => setmode(options[5].mode)}
            active={options[5].mode === mode}
          />
        )}
        {superRegex.test(role) && (
          <SidebarOption
            image={options[6].image}
            label={options[6].label}
            key={options[6].label}
            onClick={() => setmode(options[6].mode)}
            active={options[6].mode === mode}
          />
        )}
        <Button
          label="Reports"
          className="bg-purple-600 mt-6"
          labelColor="#f5f5f5"
          onClick={() => window.open("https://ibomwaterfall.9appsoft.com/")}
          right={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#f5f5f5"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          }
        />
      </div>
      <div className="flex flex-col gap-32 mt-8 pb-16 px-6 py-10">
        {superRegex.test(role) && (
          <SidebarOption
            image={options[7].image}
            label={options[7].label}
            key={options[7].label}
            onClick={() => setmode(options[7].mode)}
            active={options[7].mode === mode}
          />
        )}
        <SidebarOption
          image={options[8].image}
          label={options[8].label}
          logout={true}
          key={options[8].label}
          onClick={() => logout()}
        />
      </div>
    </div>
  );
}

export default PortalSidebar;
