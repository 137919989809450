import { useState } from "react";
import { useAppContext } from "../../helpers/store";
import LabeledInput from "../basics/labeled-input";
import moment from "moment";
import Button from "../basics/button";

export default function AvailabilityChecker() {
  const context = useAppContext();

  const [enquiryResult, setEnquiryResult] = useState([]);

  let today = moment();
  let tomorrow = moment();
  tomorrow.add(1, "days");

  const [enquiry, setEnquiry] = useState({
    dateIn: `${today.year()}-${
      today.month() + 1 < 10 ? "0" + (today.month() + 1) : today.month() + 1
    }-${today.date() < 10 ? "0" + today.date() : today.date()}`,
    dateOut: `${tomorrow.year()}-${
      tomorrow.month() < 10
        ? "0" + (tomorrow.month() + 1)
        : tomorrow.month() + 1
    }-${tomorrow.date() < 10 ? "0" + tomorrow.date() : tomorrow.date()}`,
    numOccupants: "1",
    loading: false,
  });
  return (
    <div className="bg-black/80 rounded-md text-white/90 lg:py-5 py-3 px-4 lg:px-7 mb-5">
      <p className="text-2xl font-semibold">Availability Checker</p>
      <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0 space-y-2 lg:mt-6 mt-4">
        <LabeledInput
          label="Check-In"
          type="date"
          className="border border-white/70 rounded-md px-3"
          value={enquiry.dateIn}
          onChange={(e) => setEnquiry({ ...enquiry, dateIn: e.target.value })}
        />
        <LabeledInput
          label="Check-Out"
          type="date"
          className="border border-white/70 rounded-md px-3"
          value={enquiry.dateOut}
          onChange={(e) => setEnquiry({ ...enquiry, dateOut: e.target.value })}
        />
        <select
          className="outline-none text-dark rounded-md p-2 cursor-pointer"
          onChange={(e) =>
            setEnquiry({ ...enquiry, numOccupants: e.target.value[0] })
          }
        >
          <option value="">Occupancy</option>
          <option value="1">1 Adult</option>
          <option value="2">2-4 Adults</option>
          <option value="5">5+ Adults</option>
        </select>
      </div>
      <Button
        loading={enquiry.loading}
        label="Get Available Rooms"
        className="mt-3 w-fit font-bold bg-white/80 text-dark"
        onClick={async () => {
          setEnquiryResult([]);
          setEnquiry({ ...enquiry, loading: true });
          await fetch(
            "https://api.ibomwaterfallsuites.com/check-availability",
            {
              method: "POST",
              body: JSON.stringify({
                dateIn: enquiry.dateIn,
                dateOut: enquiry.dateOut,
                numOccupants: enquiry.numOccupants,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(async (data) => {
              console.log(data);
              setEnquiryResult(data);
              setEnquiry({ ...enquiry, loading: false });
            });
        }}
      />
      <div
        className={`${
          enquiryResult.length === 0 ? "hidden" : "grid"
        } lg:grid-cols-5 grid-cols-3 gap-2 mt-3`}
      >
        {enquiryResult.map((room, i) => (
          <div className="flex space-x-2 items-center" key={i}>
            <div className="w-2 h-2 rounded-full bg-blue-400" />
            <p>
              {
                context?.rooms?.find((r, i) => r.data.roomId === room).data
                  .roomnumber
              }
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
