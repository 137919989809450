import PuffLoader from "react-spinners/PuffLoader";

const Button = ({
  className,
  disabled,
  fontSize,
  label,
  labelColor,
  loading,
  moreStyles,
  onClick,
  left,
  right,
}) => {
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  return (
    <div
      className={`${className} py-2 px-7 rounded ${
        disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"
      } flex items-center justify-center`}
      style={{
        ...moreStyles,
      }}
      onClick={() => !disabled && onClick()}
    >
      {loading ? (
        <PuffLoader
          color="#FA7C25"
          loading={loading}
          cssOverride={override}
          size={25}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <div className="flex items-center justify-center space-x-1">
          {left}
          <p style={{ color: labelColor, fontSize }}>{label}</p>
          {right}
        </div>
      )}
    </div>
  );
};

export default Button;
