import RoomTable from "../../components/widgets/room-table";
import { useAppContext } from "../../helpers/store";
import AvailabilityChecker from "../../components/widgets/availability-checker";
import Button from "../../components/basics/button";

function ManageRooms() {
  const context = useAppContext();

  return (
    <div className="w-full p-6">
      <AvailabilityChecker />
      <RoomTable
        rooms={context?.rooms}
        activeBookings={context?.activeBookings}
        manageRoom={(id, roomId, price, roomtype, roomnumber) => {
          context?.setOpenSideView(true);
          context?.setSideMode("manage-room");
          context?.setBookable({
            id,
            roomId,
            price,
            roomtype,
            roomnumber,
          });
        }}
        toggleblock={async (id, blocked) => {
          await fetch("https://api.ibomwaterfallsuites.com/block-room", {
            method: "POST",
            body: JSON.stringify({
              id,
              blocked,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }).then(() => context?.setrefresh(context?.refresh + 1));
        }}
      />
      <Button
        label="Edit Room Prices"
        className="mt-3 w-fit font-bold bg-dark text-[#f5f5f5]"
        onClick={() => {
          context?.setOpenSideView(true);
          context?.setSideMode("edit-room");
        }}
      />
    </div>
  );
}

export default ManageRooms;
